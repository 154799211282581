import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import currentChange from "moment";
const navigation = require("react-navigation");
export interface ErrorMessage {
  token: string;
}
interface TimePrice {
  time: string;
  price: string;
}
interface FlightDataAir{
  id: number;
  AirlineName: string;
  Count:number;
  checked: boolean,
}
interface City {
  airportFrom: SearchText
  airportTo: SearchText
  departureDate: Date |null;
}
export interface PopularFilterProps {
  checked: boolean;
  name: string;
}
interface DateRange {
  selection: {
    startDate: Date;
    endDate: Date;
  };
}
interface AirportSearch {
  id:string;
  Code: string;
  Name: string;
  CityCode: string;
  CountryCode: string;
}

interface AirportResponse {
  airport_list: AirportSearch[] | null;
}
export interface TripData{
  departure: string,
  destination: string,
  date: string,
  startTime: string,
  endTime: string,
  duration: string,
  stop_detail:string,
  flightName: string,
  flight_num: string
}
interface Airline {
  id: number;
  AirlineName: string;
  Count:number;
  checked:boolean;
}
export interface FlightObject {
  flight_name: string,
  duration: string,
  cost_per_adult: string,
  tripDetails: TripData[]
}
export interface SearchText {
  id: string,
  state: string,
  country: string,
  flight: string,
  stateCode: string
}
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: typeof navigation;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  filtersDrawer: boolean;
  priceListName: Array<string>;
  anchorElTripType: HTMLElement | null;
  fromAnchorEl: HTMLElement | null;
  toAnchorEl: HTMLElement | null;
  anchorElClass: HTMLElement | null;
  tripOpen: boolean;
  anchorEl: HTMLElement | null;
  anchorElDeparture: HTMLElement |null
  selectedTripType: string;
  anchorElTo: null;
  flightDetailsIndex: number | null
  inputCityFrom:string;
  inputCityTo:string;
  cities: City[];
  stopsArray: {type:string,cost:string}[]
  fullTravelObj: {
    countAdult: number,
    countChild: number,
    travelStatus: string,
    countInfant: number
  }
  showTrip: boolean;
  isOpen: boolean;
  arrivalTimePrice:TimePrice | null;
  departureTimePrice:TimePrice | null;
  startDate: Date | null;
  endDate: Date | null;
  endDate1: Date | null;
  countAdult: number;
  travelStatus: string;
  countChild: number;
  countInfant: number;
  error: string;
  showPopularMore: boolean
  infant: number
  cabin_class: string
  activePicker: string;
  currentIndex: null| number;
  flightDetailsDialog: boolean;
  flightDesignation: Array<string>;
  airlinesList: Airline[]
  ShowFlightDetails: boolean;
  searchFrom: string
  searchFromMultiCity: string
  searchToMultiCity: string
  filteredFromItems: SearchText[];
  filteredToItems: SearchText[];
  fromInputSearchValue: string
  toInputSearchValue: string
  setAnchorAi: Element | null;
  selectedOption:string
  timePriceArray: TimePrice[]
  shownPopularFilters: PopularFilterProps[]
  selectedPopularFilter:PopularFilterProps[]
  popularFilters: PopularFilterProps[]
  airLinesData:FlightDataAir[]
  selectedAirlines:FlightDataAir[]
  showAirlineMore:boolean,
  fromObj: {
    state: string,
    country: string,
    flight: string,
    stateCode: string
  };
  toObj: {
    state: string,
    country: string,
    flight: string,
    stateCode: string
  }
  flightArray:FlightObject[]
  minPrice: number,
  maxPrice: number,
  selectedPrice: number,
  shownAirLineList: Airline[]
  selectedFareIndex: number | null,
  selectedFare: string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ClientViewProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  editFullNameAPICallId: string = "";
  airportSearchApiCallId: string = '';
  airportSearchToApiCallId: string =''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationPropsMessage),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      filtersDrawer: false,
      anchorElDeparture: null,
      showPopularMore: false,
      infant:0,
      flightDetailsIndex: null,
      showAirlineMore:false,
      inputCityTo:"",
      stopsArray:[{type:'Non-Stop(16)',cost:'$5352'},{type:' 1 Stop (4)',cost:'$5352'}],
      inputCityFrom:"",
      fullTravelObj: {
        countChild: 0,
        travelStatus: "Economy",
        countAdult: 1,
        countInfant: 0,
      },
      selectedFare: '',
      selectedFareIndex: null,
      searchFromMultiCity: '',
      searchToMultiCity: '',
      airLinesData:[{ id: 1, AirlineName: "Air India", Count: 5232, checked: false },
      { id: 2, AirlineName: "Indigo", Count: 5232, checked: false },
      { id: 3, AirlineName: "Akasa Air", Count: 5232, checked: false },
      { id: 4, AirlineName: "SpiceJet", Count: 5232, checked: false },],
      selectedAirlines: [],
      minPrice: 500,
      maxPrice: 25000,
      arrivalTimePrice: null,
  departureTimePrice: null,
      selectedPrice: 0,
      selectedPopularFilter:[],
      timePriceArray: [
        { time: "Before 6AM", price: "$4200" },
        { time: "6AM to 12PM", price: "$5200" },
        { time: "12PM to 6PM", price: "$6200" },
        { time: "After 6PM", price: "$6500" }
      ],
      shownAirLineList: [{ id: 1, AirlineName: "Air India", Count: 5232, checked: false },
      { id: 2, AirlineName: "Indigo", Count: 5232, checked: false },
      ],
      airlinesList:
        [{ id: 1, AirlineName: "Air India", Count: 5232, checked: false },
        { id: 2, AirlineName: "Indigo", Count: 5232, checked: false },
        { id: 3, AirlineName: "Akasa Air", Count: 5232, checked: false },
        { id: 4, AirlineName: "SpiceJet", Count: 5232, checked: false },],

      shownPopularFilters: [
        { checked: false, name: "Non-stop" },
        { checked: false, name: "1 Stop"},
        { checked: false, name: "2 Stop"},
        { checked: false, name: "Morning Departures"},
        { checked: false, name: "Late Departures"},
      ],
      popularFilters: [
        { checked: false, name: "Non-stop" },
        { checked: false, name: "Indigo", },
        { checked: false, name: "Morning Departures"},
        { checked: false, name: "Late Departures"},
        { checked: false, name: "On time",},
        { checked: false, name: "Air India"},
        { checked: false, name: "One-way"},
        { checked: false, name: "Late Departures"}
      ], 
      priceListName: [
        "FARES",
        "CABIN BAG",
        "CHECK-IN",
        "CANCELLATION",
        "DATE CHANGE",
        "SEAT",
        "MEAL",
      ],
      toInputSearchValue:'',
      searchFrom:'',  
      setAnchorAi: null,
      selectedOption:'',
      anchorElTripType: null,
      fromInputSearchValue: '',
      flightArray:[
        {
          flight_name: "Airline A",
          duration: "5h 30m",
          cost_per_adult: "$37,860",
          tripDetails: [
            {
              departure: "New York",
              destination: "Los Angeles",
              date: "Mon, 22 Apr",
              startTime: "10:00 AM",
              endTime: "3:30 PM",
              duration: "5h 30m",
              stop_detail: "Non-stop",
              flightName: "Airline A",
              flight_num: "AA123"
            },{
              departure: "Chicago",
              destination: "Miami",
              date: "Mon, 22 Apr",
              startTime: "2:00 PM",
              endTime: "9:15 PM",
              duration: "7h 15m",
              stop_detail: "1 stop via Atlanta",
              flightName: "Airline B",
              flight_num: "BA456"
            }
          ]
        },
        {
          flight_name: "Airline B",
          duration: "7h 15m",
          cost_per_adult: "$37,860",
          tripDetails: [
            {
              departure: "Chicago",
              destination: "Miami",
              date: "Mon, 22 Apr",
              startTime: "2:00 PM",
              endTime: "9:15 PM",
              duration: "7h 15m",
              stop_detail: "1 stop (Atlanta)",
              flightName: "Airline B",
              flight_num: "BA456"
            }
          ]
        },
        {
          flight_name: "Airline C",
          duration: "9h 20m",
          cost_per_adult: "580 USD",
          tripDetails: [
            {
              departure: "San Francisco",
              destination: "London",
              date: "Mon, 22 Apr",
              startTime: "8:30 PM",
              endTime: "5:50 AM",
              duration: "9h 20m",
              stop_detail: "Non-stop",
              flightName: "Airline C",
              flight_num: "CA789"
            }
          ]
        }
      ],
      tripOpen: false,
      anchorEl: null,
      cabin_class: '',
      fromObj: {
        state: "",
        country: "",
        flight: "",
        stateCode: ""
      },
      toObj: {
        state: "",
        country: "",
        flight: "",
        stateCode: ""
      },
      selectedTripType: "Multicity",
      anchorElTo: null,
      cities: [{
        airportFrom: {
          id: "",
          state: "",
          country: "",
          flight: "",
          stateCode: ""
        },airportTo: {
          id: "",
          state: "",
          country: "",
          flight: "",
          stateCode: ""
        }, departureDate: null
      }],
      showTrip: false,
      isOpen: false,
      startDate: null,
      endDate: null,
      endDate1: null,
      countAdult: 1,
      filteredFromItems: [],
      filteredToItems: [],
      travelStatus: "Economy",
      countChild: 0,
      countInfant: 0,
      fromAnchorEl: null,
      toAnchorEl: null,
      anchorElClass: null,
      error: "",
      activePicker: "",
      currentIndex: null,
      flightDetailsDialog: false,
      flightDesignation: ["Saver","Comfort","Comfort Plus","Flex"],
      ShowFlightDetails: false
    };

    // Customizable Area End
  }

  // Customizable Area Start
  handleFiltersToggle = () => {
    this.setState({ filtersDrawer: !this.state.filtersDrawer });
  };

  closeTripTypeMenu = () => {
    this.setState({ anchorElTripType: null });
  };

  handleClick = (event: React.MouseEvent<HTMLElement>,index:number) => {
    const citiesVal = [...this.state.cities];
    if (citiesVal[index].airportFrom.state) {
      citiesVal[index].airportFrom = {
        id: '',
        state: "",
        country: "",
        stateCode: "",
        flight: ""
      };
    }
    this.setState({ anchorEl: event?.currentTarget,filteredFromItems:[],currentIndex:index,cities:citiesVal,searchFromMultiCity:'' });
  };
  handleSelectPrice = (item:string,index:number)=>{
    this.setState({selectedFare:item,selectedFareIndex: index})
  }
  handleCloseTo = (selectedItem: SearchText, index: number) => {
    const citiesVal = [...this.state.cities];

    if (citiesVal[index]) {
      citiesVal[index].airportTo = selectedItem;
    }
  
    this.setState({
      cities: citiesVal,
      anchorElTo: null,
      currentIndex: null,
      toObj: selectedItem,
      searchToMultiCity:''
    });
  }
  

  togglePicker = (picker: string,event: React.MouseEvent<HTMLElement>,index:number) => {

    this.setState({
      isOpen: true,
      activePicker: picker,
      anchorElDeparture: event.currentTarget,
      setAnchorAi: event.currentTarget,
      currentIndex: index
    });


  };
  handleSelectTriptype = (selecte: string) => {
    this.setState({ selectedTripType: selecte ,anchorElTripType:null});
  };
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
   
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId != null) {
        switch (true) {
          case apiRequestCallId === this.airportSearchApiCallId && this.airportSearchApiCallId!=null:
            this.handleAirportSearchRes(responseJson)
            break;
          case apiRequestCallId === this.airportSearchToApiCallId && this.airportSearchToApiCallId!=null:
            this.handleAirportSearchToRes(responseJson)
          break
         default:
        }
      }
      }
    // Customizable Area End
  }
  handleAirportSearchRes = (responseJson: AirportResponse) => {
    if (responseJson.airport_list != null) {
      const caData = responseJson.airport_list.map((item: AirportSearch, index: number) => {
        let flightName = item.Name.replace("Arpt", "Airport");
        return {
          id: `${index + 1}`,
          country: item.CountryCode,
          state: item.Code,
          flight: flightName,
          stateCode: item.CityCode
        };
      });

      this.setState({ filteredFromItems: caData });
      this.setState({
        fromObj: {
          ...this.state.fromObj,
          state: this.state.fromObj.state
        },
      });
    } else {
      this.setState({ fromObj: { ...this.state.fromObj, state: "" } });
    }
  }

  showLessPopularFiltersList = ()=>{
    const { popularFilters } = this.state;
    const reducedPopularList =  popularFilters.slice(0, 4);
    this.setState({shownPopularFilters: reducedPopularList})
    this.setState({showPopularMore: false})
  }
  showMorePopularFiltersList = ()=>{
    this.setState({shownPopularFilters: this.state.popularFilters})
    this.setState({showPopularMore: !this.state.showPopularMore})
  }
  handlePriceChange = (event:React.ChangeEvent<{}>, value: number | number[]) => {
    this.handlePriceRangeChange(value as number);
  };
  handlePriceRangeChange = (maxValue: number) => {
    this.setState({ selectedPrice: maxValue })
  }
  handleAddCity = () => {
    let { cities } = this.state
    if (cities.length < 6) {
      this.setState((prevState) => ({
        cities: [...prevState.cities, {
          airportFrom: {
            id: "",
            state: "",
            country: "",
            flight: "",
            stateCode: ""
          }, airportTo: {
            id: "",
            state: "",
            country: "",
            flight: "",
            stateCode: ""
          }, departureDate: null
        }],
      }));
      this.setState({ fromInputSearchValue: '',searchFromMultiCity:'',searchToMultiCity:"", toInputSearchValue: '', startDate: null, endDate: null, endDate1: null })
    }
  };

  handleClosePopup = () => {
    this.setState({ anchorEl: null, toAnchorEl: null, currentIndex: null, searchFrom: "", filteredFromItems: [] });
  };
  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target;
    this.setState({
      fromInputSearchValue: value,
      searchFromMultiCity: value
    }, () => {
      if (value) {
        this.airportSearch(value);
      } else {
        this.setState({
          filteredFromItems: [],
          fromObj: {
            country: "",
            stateCode: "",
            flight: "",
            state: ""
          }
        });
      }
    });
  };
  
  showMoreAirlineFiltersList = () => {
    this.setState({ shownAirLineList: this.state.airLinesData });
    this.setState({ showAirlineMore: true });
  }
  
  showLessAirlineFiltersList = () => {
    const { airLinesData } = this.state;
    const reducedPopularList = airLinesData.slice(0, 2);
    this.setState({ shownAirLineList: reducedPopularList });
    this.setState({ showAirlineMore: false });
  }
  
  airportSearch=(stateName:string)=>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.airportSearchApiCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.airportSearchEndPoint+ stateName
    );
  
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleOpenTripType =(event: React.MouseEvent<HTMLElement>)=>{
    this.setState({anchorElTripType: event.currentTarget})
  }
  handleSingleClose = (option: SearchText, index: number) => {

    const citiesVal = [...this.state.cities];
  
    if (citiesVal[index]) {
      citiesVal[index].airportFrom = option;
    }
  
    this.setState({
      cities: citiesVal,
      anchorEl: null,
      currentIndex: null,
      fromObj: option,
      fromInputSearchValue:''
    });
  }
  

  handleRemoveCity = (indexNumber: number) => {
    let {cities} = this.state
    if(cities.length === 1){
      this.setState({showTrip:false})
    }
    this.setState((prevState) => {
      const cities = [...prevState.cities];
      cities.splice(indexNumber, 1);
      return { cities };
    });
  };
  reverseFunct = (index: number) => {
    let arrayData = [...this.state.cities];
  
    if (arrayData[index]) {
 
      const tempAirportFrom = arrayData[index].airportFrom;
      arrayData[index].airportFrom = arrayData[index].airportTo;
      arrayData[index].airportTo = tempAirportFrom;
    }

    this.setState({
      cities:arrayData
    })
  };
  
  handleClickStatus = (buttonName: string) => {
    this.setState({
      travelStatus: buttonName,
      cabin_class: buttonName,
    });
  };
  openTripOption = () => {
    let {cities } = this.state
    if(cities.length === 0){
      this.setState({cities:[{
        airportFrom: {
          id: "",
          state: "",
          country: "",
          flight: "",
          stateCode: ""
        },airportTo: {
          id: "",
          state: "",
          country: "",
          flight: "",
          stateCode: ""
        }, departureDate: null
      }]})
    }
    this.setState({ showTrip: true });
  };

  openClassCategory = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorElClass: event?.currentTarget });
  };

  closeClassCategory = () => {
    this.setState({ anchorElClass: null });
  };

  countDecrease = () => {
    this.setState((prevState) => ({
      countAdult:
        prevState.countAdult === 1
          ? prevState.countAdult
          : prevState.countAdult - 1,
      error: "",
    }));
  };
  handleDepartureTimeSelect = (time: TimePrice) => {
    this.setState({
      departureTimePrice: time,
    });
  }
  handleArrivalTimeSelect=(time:TimePrice)=>{
    this.setState({ 
        arrivalTimePrice: time,
    });
  }

  errorFunct = (errorMsg: string) => {
    this.setState({ error: errorMsg });
  };
  handleCloseCustomAi = () => {
    this.setState({
      setAnchorAi: null,
      isOpen: false,
    });
  }
  handleDateChange = (ranges: DateRange) => {
    const { startDate, endDate } = ranges.selection;

    this.setState({
      startDate,
      endDate,
      endDate1:endDate
    });
  };
  handleApply = (index:number) => {
    const citiesVal = [...this.state.cities];
    if (citiesVal[index]) {
      citiesVal[index].departureDate = this.state.startDate;
    }
    this.setState({ isOpen: false, activePicker: "",currentIndex:null });
  };

  handleCancel = () => {
    this.setState({ startDate: null,currentIndex:null, activePicker: "", endDate: null, endDate1: null, isOpen: false, });
  };
  handleDateChange1 = (ranges: DateRange) => {
    if (this.state.activePicker === 'startDate') {
      let determinedDate = ranges.selection.startDate;

      this.setState({
        startDate: determinedDate,
        endDate: determinedDate,
        endDate1: null,
      });
    }
  };
  countIncrease = () => {
    this.setState((prevState) => {
      const total =
        prevState.countChild + prevState.countInfant + prevState.countAdult;
      if (total < 9) {
        return {
          countAdult: prevState.countAdult + 1,
          error: "",
        };
      } else {
        this.errorFunct(
          "If you want to add more than 9 travellers please contact email www.doolwa.com"
        );
        return null;
      }
    });
  };
  handleClickTo = (event: React.MouseEvent<HTMLElement>, index:number) => {
    const citiesVal = [...this.state.cities];
    if (citiesVal[index].airportTo.state) {
      citiesVal[index].airportTo = {
        id: '',
        state: "",
        country: "",
        stateCode: "",
        flight: ""
      };
    }
    this.setState({
      toAnchorEl: event.currentTarget, toObj: {
        country: "",
        stateCode: "",
        flight: "",
        state: ""

      }, currentIndex: index, filteredToItems:[], toInputSearchValue: "", cities: citiesVal, searchToMultiCity: ''
    });
  };
  handleInputChangeTo = (event:React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event?.target; 
    this.setState({ toInputSearchValue:value,searchToMultiCity:value,
    }, () => {
      if (value !== "") {
        this.airportSearchTo(value);
      } else {
        this.setState({
          filteredToItems: [],
          toObj: {
            country: "",
            stateCode: "",
            flight: "",
            state: ""
           
          }
        });
      }
    });
  };
  airportSearchTo=(stateName:string)=>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.airportSearchToApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.airportSearchEndPoint+ stateName
    );
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleAirportSearchToRes=(responseJson:AirportResponse)=>{
    if(responseJson && responseJson.airport_list!=null){
      const caData = responseJson.airport_list.map((item:AirportSearch, index:number) => {
        let flightName = item.Name.replace("Arpt", "Airport");
        return {
          "id":`${index+1}`,
          "country":item.CountryCode,
          "state":item.Code,
          "flight":flightName,
          "stateCode":item.CityCode 
        }
     })
     this.setState({filteredToItems:caData})
        
        this.setState({ 
          toObj: { 
            ...this.state.toObj, 
            state: this.state.toObj.state 
          }, 
        });
      
    }else{
      this.setState({toObj: { ...this.state.toObj, state: ""}})
    }
     
  }
  countChildDecrease = () => {
    this.setState((prevState) => ({
      countChild:
        prevState.countChild === 0
          ? prevState.countChild
          : prevState.countChild - 1,
      error: "",
    }));
  };

  countChildIncrease = () => {
    this.setState((prevState) => {
      const total =
        prevState.countChild + prevState.countInfant + prevState.countAdult;
      if (total < 9) {
        return {
          countChild: prevState.countChild + 1,
          error: "",
        };
      } else {
        this.errorFunct(
          "If you want to add more than 9 travellers please contact email www.doolwa.com"
        );
        return null;
      }
    });
  };

  countChildInfantDecrease = () => {
    this.setState((prevState) => ({
      countInfant:
        prevState.countInfant === 0
          ? prevState.countInfant
          : prevState.countInfant - 1,
      error: "",
    }));
  };

  countChildInfantIncrease = () => {
    this.setState((prevState) => {
      const total =
        prevState.countChild + prevState.countInfant + prevState.countAdult;
      if (total < 9) {
        return {
          countInfant: prevState.countInfant + 1,
          error: "",
        };
      } else {
        this.errorFunct(
          "If you want to add more than 9 travellers please contact email www.doolwa.com"
        );
        return null;
      }
    });
  };

  handleCancelTravelButton = () => {
    this.setState({
      travelStatus: "Economy", countAdult: 1, countChild: 0,countInfant:0,infant:0,cabin_class: "Economy",
      fullTravelObj: {
        countAdult: 1,
        countChild: 0,
        countInfant: 0,
        travelStatus: "Economy",
      },
      anchorElClass: null,
      error: ""
    })
  };

  handleDoneButton = () => {
    this.setState({
      anchorElClass: null,
      fullTravelObj:{
        countAdult: this.state.countAdult,
        countChild: this.state.countChild,
        countInfant: this.state.countInfant,
        travelStatus: this.state.travelStatus,
      }
    });
  };

  totalTravellers = () => {
    return `${Number(this.state.countAdult)} Adults ${this.state.travelStatus}`;
  };

  flightDialogClose = () => {
   this.setState({flightDetailsDialog: false,selectedFareIndex:null,selectedFare:""});
  };

  flightDialogOpen = () => {
    this.setState({flightDetailsDialog: true});
   };
   handleBookNow = ()=>{
    this.props.navigation.navigate("OrderManagementMultiCity")
   }
   handleFlightDetails = (index: number) => {
    this.setState((prevState) => ({
      ShowFlightDetails:
        prevState.flightDetailsIndex === index
          ? !prevState.ShowFlightDetails 
          : true,                       
      flightDetailsIndex: index
    }));
  };
  
  // Customizable Area End
}
